<template>
  <div id="popinWithPicture">
    <molecules-popin
      v-if="popinPayload"
      ref="popinWithPicture"
      v-bind="popinPayload"
      :picture="popinPayload.picture"
    >
      <organisms-confirmation-block
        left-aligned
        v-bind="popinPayload.block"
        @back="onClick"
      />
    </molecules-popin>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { PrismicState } from '../store/prismic/state'
import { injectPhoneNumber, removeHTMLTags } from '~~/utils/prismic'
import { extractPopinPayloadFromBody } from '~~/utils/prismicSlices/popin'
import { useI18n } from '#imports'

export default defineNuxtComponent({
  name: 'PopinMaintenance',
  setup: () => {
    const i18n = useI18n()

    return {
      i18n,
    }
  },
  computed: {
    ...(mapGetters({
      stepsData: 'prismic/stepsData',
    }) as {
      stepsData: () => PrismicState['stepsData']
    }),
    popinPayload() {
      const stepsData = this.stepsData as PrismicState['stepsData']

      if (!stepsData.offers) return

      const payload = (extractPopinPayloadFromBody(
        stepsData.family as { body: PrismicPopinSliceData[] },
        'maintenance_mode'
      ) || [])[0]

      const phoneNumber = this.i18n.t('header.contact.label') as string

      return {
        isDismissable: false,
        block: {
          title: payload.title,
          text: injectPhoneNumber(payload.text, phoneNumber, true),
          primaryCta: {
            label: removeHTMLTags(payload.primaryCta),
            isBack: true,
            link: {
              variant: 'primary',
              ...payload.primaryCtaHref,
            },
          },
        },
        picture: payload.picture?.cdn_link,
      }
    },
  },
  mounted() {
    this.handleToggle()
  },
  methods: {
    onClick() {
      window.open(
        this.popinPayload?.block.primaryCta.link?.url,
        this.popinPayload?.block.primaryCta.link?.target
      )
    },
    handleToggle() {
      ;(this.$refs.popinWithPicture as any).handleToggle()
    },
  },
})
</script>

<style lang="scss"></style>
